import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { GlobalContactForm } from '@phx-husky/global-contact-form';
import { Layout, DangerouslySetInnerHtml } from 'layout';
import Banner from 'components/Banner';
import { ContactUsPageComponentProps } from './models';
import './ContactUsPageMain.scss';
import './ContactUsPageRTL.scss';

const ContactUsPage: FC<ContactUsPageComponentProps> = ({
  data: {
    umbracoHome: { disclaimerDesktopImage, disclaimerMobileImage },
    umbracoContactUs: {
      seoMetaKeywords,
      seoMetaTitle,
      seoMetaDescription,
      seoExternalHreflangs,
      defaultCompositions,
      banner,
      text,
      url,
      contactFormLink,
      contactFormTitle,
    },
  },
}) => (
  <Layout
    {...{ defaultCompositions, disclaimerDesktopImage, disclaimerMobileImage }}
    seo={{
      seoMetaKeywords,
      seoMetaTitle,
      seoMetaDescription,
      seoExternalHreflangs,
    }}
    className="contact-us-page"
  >
    {banner.map(({ properties: { title, ...restBannerProperties } }) => (
      <Banner
        url={url}
        key={title}
        title={title}
        {...{ ...restBannerProperties, image: restBannerProperties.localImage }}
      />
    ))}
    {text ? (
      <div className="contact-us-page__wrapper">
        <DangerouslySetInnerHtml html={text} className="contact-us-page__text" />
      </div>
    ) : null}
    {contactFormLink && contactFormTitle ? (
      <div className="contact-us-page__global-form-wrapper">
        <GlobalContactForm
          src={contactFormLink}
          id="contact-form"
          title={contactFormTitle}
          className="contact-form"
        />
      </div>
    ) : null}
  </Layout>
);

export const query = graphql`
  query ContactUsPage($url: String = "", $lang: String) {
    umbracoContactUs: umbracoContactUs(url: { eq: $url }, lang: { eq: $lang }) {
      id
      url
      text
      banner {
        properties {
          title
          variant
          localImage {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          imageAlt
          backgroundColour
        }
      }
      seoMetaTitle
      seoMetaDescription
      seoMetaKeywords
      seoExternalHreflangs {
        hreflang: key
        href: value
      }
      defaultCompositions {
        ...DefaultCompositionsFragment
      }
      contactFormLink
      contactFormTitle
    }
    umbracoHome(lang: { eq: $lang }) {
      disclaimerMobileImage {
        ...FragmentImageWithAlt
      }
      disclaimerDesktopImage {
        ...FragmentImageWithAlt
      }
    }
  }
`;

export default ContactUsPage;
